module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"className":"header-link-icon","offsetY":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Riverscapes Consortium","short_name":"Riverscapes","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"/home/runner/work/developer-website/developer-website/node_modules/@riverscapes/gatsby-theme/src/images/favicon/data-exchange-icon-64x64.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b520aa0e99a42ad2fe6177baa4e8eb0a"},
    },{
      plugin: require('../node_modules/@riverscapes/gatsby-theme/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"/home/runner/work/developer-website/developer-website/content/page","manifest":{"name":"Riverscapes Consortium","short_name":"Riverscapes","start_url":"/"}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
